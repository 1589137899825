<template>
  <div class="circleXq_main">
    <pageHead
      class="messageMainView_head"
      title="圈子详情"
      :pageHead_fx="false"
    />
    <div
      ref="content"
      class="message_scroll"
      infinite-scroll-throttle-delay="500"
      infinite-scroll-distance="50"
      v-infinite-scroll="loadMore"
    >
      <div
        class="flex-column banner_back"
        :style="{ backgroundColor: getBgColor() }"
      >
        <HeaderInfo
          @quitCircle="handleQuitCircle"
          @joinAppleBtn="handleJoinApplyBtn"
          @joinBtn="handleJoinBtn"
          @showDetail="handleShowDetail"
          :circle-detail="circleDetail"
        ></HeaderInfo>
      </div>
      <div class="circle_content">
        <div class="left">全部帖子</div>
        <div class="right">
          <div @click.prevent="searchCircle">
            <img
              class="img1"
              src="../../../../assets/imgs/circle/Slice46@2x.png"
              alt=""
            />
          </div>
          <div @click.prevent="changeSort">
            <span>{{
                this.listQueryParams.sortType === 1 ? "最新" : "最热"
              }}</span>
            <img
              class="img2"
              src="../../../../assets/imgs/circle/Slice48@2x.png"
              alt=""
            />
          </div>
        </div>
        <van-action-sheet
          v-model="rigthShow"
          :actions="rightActions"
          cancel-text="取消"
          close-on-click-
          @select="rightSelect"
          @cancel="rightOnCancel"
        />
      </div>
      <!-- 帖子列表 -->
      <div class="topic-list">
        <topic
          v-for="(item, index) in topicData"
          :id="'circleAId'+ item.articleId"
          :key="index"
          :data="item"
          :isList="true"
          :circle-detail="circleDetail"
          :circleShow="true"
          class="topicItem"
          @deleteArticle="handleDeleteArticle"
          @comment="handleComment"
          @userInfoClick="handleToUserDetail(item.userBasicVO.userId)"
          @share="handleShare"
          @like="like($event, index)"
          @favorite="favorite($event, index)"
          @originalClick="handleTopicClick"
          @click="handleTopicClick"
          @tagClick="handleTagClick"
          @attClick="handleToUserDetail"
          @follow="handleFollow(item, index)"
          @handleNewActive="handleNewActive"
        />
        <van-empty
          v-if="isLoaded && topicData.length === 0"
          description="无发帖内容"
        />
        <no-more
          v-if="
            listQueryParams.hasNextPage === false &&
            isLoaded &&
            topicData.length > 0
          "
        />
      </div>
    </div>

    <!-- todo 圈子简介展示 -->
    <div class="van_popup_">
      <van-popup :style="{ maxHeight: '90%' }" position="bottom" v-model="circleInfoShow" closeable>
        <CircleInfo :circleDetail="circleDetail"></CircleInfo>
      </van-popup>
    </div>

    <!-- 发帖按钮 -->
    <div class="addBtn">
      <img src="/images/appMainView/add.png" @click="handlerAdd" />
      <div class="wave wave0"></div>
    </div>

    <!-- 弹窗提醒, 申请原因 -->
    <van-dialog
      v-model="isShowApply"
      title="加入原因"
      @close="applyReason = ''"
      @confirm="handleConfirmApply"
      :show-cancel-button="true"
      get-container="#app"
    >
      <van-cell-group slot="default">
        <van-field
          maxlength="30"
          show-word-limit
          ref="applyReason"
          v-model="applyReason"
          label="申请原因"
          :formatter="vanFieldFormatter"
          placeholder="请输入申请原因"
        />
      </van-cell-group>
    </van-dialog>

    <!-- 评论弹窗 -->
    <commentToolDialog
      :show-dialog.sync="showCommentDialog"
      :isAnonymousComment.sync="isAnonymousComment"
      v-model="commentContent"
      hide-img
      :iosMarginBottom="iosMarginBottom"
      @publish="handlePublishComment"
      :attList="attList"
      @delete="handleDeleteAtt"
    />
    <!--    <van-dialog-->
    <!--      v-model="isShowQuit"-->
    <!--      title="退出圈子"-->
    <!--      @confirm="handleConfirmQuit"-->
    <!--      :show-cancel-button="true">-->
    <!--      <div class="default-quit" slot="default">-->
    <!--        <span>{{ quitTips }}</span>-->
    <!--      </div>-->
    <!--    </van-dialog>-->
  </div>
</template>

<script>
import {getItem, removeItem} from '@/utils/storage'
import pageHead from "@/components/pageHead";
import CircleInfo from "./circleInfo.vue";
import HeaderInfo from "./components/headerInfo";
import {vanFieldFormatter} from '@/utils/utils'
import {getCircleDetail, listCircleArticle, setApplyJoin} from "@/api/circle";
import {addThumbsup, removeThumbsup} from "@/api/thumbsup";
import {addFavorites, removeFavorites} from "@/api/favorites";
import topic from "@/components/topicItem";
import {addComment} from "@/api/comment";
import {isAndroid} from "@/utils/plamtom";
import commentToolDialog from "@/components/commentToolDialog";
import NoMore from "@/components/uitls/NoMore";

const ENTITY_TYPE_ARTICLE = 2;
let timer;
export default {
  name: "circleXq", // 圈子详情
  components: {
    NoMore,
    pageHead,
    CircleInfo,
    HeaderInfo,
    topic,
    commentToolDialog,
  },
  data () {
    let ios = !isAndroid();
    return {
      articleIdList: [], // 帖子id列表
      isLoaded: false,
      iosMarginBottom: ios,
      attList: [],
      isShowApply: false,
      applyReason: "",
      circleDetail: {}, // 圈子详情
      show: false,
      rigthShow: false,
      showDiaolog: false, // 弹窗
      right: false, // 控制右边按钮
      circleInfoShow: false,
      ly: "",
      actions: [{name: "退出"}],
      rightActions: [{name: "置顶"}, {name: "删除"}],
      header: {},
      commentParams: {},
      showCommentDialog: false, // 评论弹窗
      commentContent: "",
      isAnonymousComment: false,
      topicData: [], // 帖子列表
      listQueryParams: {
        circleId: this.$route.query.id,
        hasNextPage: true,
        pageNum: 1,
        pageSize: 10,
        sortType: 1,
      },
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (to, from) {
        console.log(to, from);
        if (
          to.query.id &&
          to.query.id != this.listQueryParams.circleId &&
          to.query.refreshCircle
        ) {
          this.circleDetail = {};
          this.topicData = [];
          (this.listQueryParams = {
            circleId: to.query.id,
            hasNextPage: true,
            pageNum: 1,
            pageSize: 10,
            sortType: 1,
          }),
            this.loadList();
          this.$route.query.id = to.query.id;
          this.getCircleDetail();
        }
      },
    },
  },
  created () {
    this.getCircleDetail();
  },
  beforeRouteEnter (to, from, next) {
    document.title = '圈子详情'
    next((vm) => {
      if (from.name === "organization") {
        // 从@页面回来重新渲染@列表
        if (vm.$route.query.selectMembers) {
          vm.attList = vm.$route.query.selectMembers
            .split(",")
            .filter((item) => item)
            .map((item) => {
              const [attId, attName] = item.split(":");
              return {attId, attName};
            });
        }
        vm.commentParams =
          localStorage.getItem("commentParams") &&
          JSON.parse(localStorage.getItem("commentParams"));
      } else if (
        // fromRouter === 'circle' && articleId
        (from.name === "createTopic" || from.name === "topicCategory") &&
        localStorage.getItem("refreshFlag")
      ) {
        // 发表完帖子刷新首页
        vm.topicData = [];
        vm.listQueryParams = {
          pageNum: 1,
          pageSize: 10,
          hasNextPage: true,
          categoryId: "1",
          sortType: "1",
          circleId: vm.circleId,
        };
        // console.log(vm.circleId);
        // vm.loadList();
        // vm.getCircleDetail();
        // vm.horselampList();
        localStorage.removeItem("refreshFlag");
      } else if (from.name === "topicDetail") {
        // 帖子详情页删除帖子需要刷新页面
        const refreshFlag = getItem('refreshFlag')
        if (refreshFlag === 'true' || refreshFlag) {
          vm.refreshArticleList();
          removeItem('refreshFlag')
        } else {
          // 帖子详情页面返回回显帖子数据
          const {id, thumbsUpFlag, relationVO} = JSON.parse(
            localStorage.getItem("refreshData")
          );
          vm.topicData.forEach((item) => {
            if (item.id === id) {
              item.thumbsUpFlag = thumbsUpFlag;
              item.relationVO = relationVO;
            }
          });
        }
      }
    });
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === "organization") {
      localStorage.setItem("commentParams", JSON.stringify(this.commentParams));
    }
    localStorage.setItem(
      "scrollTop",
      JSON.stringify(this.$refs.content.scrollTop)
    );
    next();
  },
  activated () {
    // timer = setInterval(() => {
    //   // this.horselampList();
    // }, 1000 * 60);
    this.getCircleDetail();
    this.$refs.content.scrollTop =
      localStorage.getItem("scrollTop") &&
      JSON.parse(localStorage.getItem("scrollTop"));
    this.scrollArticleId()
  },
  deactivated () {
    clearInterval(timer);
  },
  methods: {
    vanFieldFormatter,
    // 滚动到刚发布的帖子
    scrollArticleId () {
      const publishNewArticleDetail = getItem('publishNewArticleDetail') || {}
      const {articleId} = publishNewArticleDetail || ''
      const {fromRouter} = publishNewArticleDetail || ''
      if (articleId && fromRouter === 'circle') {
        if (this.topicData.length === 0) {
          this.listQueryParams.pageNum = 1
          this.listQueryParams.hasNextPage = true
          this.loadList()
        } else {
          if (this.articleIdList.indexOf(articleId) !== -1) {
            this.$nextTick(() => {
              //平滑的跳转
              let element = document.getElementById(`circleAId${articleId}`);
              if (element) {
                element.scrollIntoView()
                // element.scrollIntoView({
                //   behavior: 'smooth',
                //   block: 'top',
                // })
                removeItem('publishNewArticleDetail')
              }
            })
          } else {
            // console.log(this.articleIdList)
            if (this.listQueryParams.pageNum < 6) {
              this.loadList()
            }
          }
        }
      }
    },
    // 圈子或者管理员删除帖子
    handleDeleteArticle (articleId) {
      // this.topicData = this.topicData.filter(item => item.articleId !== articleId)
      if (this.topicData.length >= 10) {
        this.topicData = this.topicData.filter(
          (item) => item.articleId !== articleId
        );
      } else {
        this.refreshArticleList()
      }
      // 重新加载圈子信息
      this.getCircleDetail();
    },
    // 刷新帖子列表
    refreshArticleList () {
      this.topicData = [];
      this.listQueryParams.pageNum = 1;
      this.listQueryParams.hasNextPage = true;
      this.loadList();
    },
    // 获取背景颜色
    getBgColor () {
      const backgroundInfo = this.circleDetail.backgroundInfo;
      if (backgroundInfo) {
        console.log(backgroundInfo);
        if (backgroundInfo.value) {
          return backgroundInfo.value;
        }
      }
      return "#F8F8F8";
    },
    loadMore () {
      this.loadList();
    },
    changeSort () {
      this.listQueryParams.sortType =
        this.listQueryParams.sortType === 1 ? 2 : 1;
      this.topicData = [];
      this.listQueryParams.pageNum = 1;
      this.listQueryParams.hasNextPage = true;
      this.loadList();
    },
    // 加载列表
    loadList () {
      let listQueryParams = this.listQueryParams;
      if (!this.listQueryParams.circleId) {
        if (this.$route.query.id) {
          this.listQueryParams.circleId = this.$route.query.id
        } else {
          return
        }
      }
      if (listQueryParams.hasNextPage) {
        this.articleIdList = []
        listCircleArticle(this.listQueryParams).then((res) => {
          listQueryParams.hasNextPage = res.current < res.pages;
          listQueryParams.pageNum = listQueryParams.pageNum + 1;
          let resData = this.topicData;
          res.records.forEach((item) => {
            let count = resData.filter(
              (innerItem) => innerItem.articleId === item.articleId
            );
            this.articleIdList.push(item.articleId)
            if (count.length === 0) {
              resData.push(item);
            }
          });
          this.topicData = resData;
          this.isLoaded = true;
          this.scrollArticleId()
        }).catch(() => {
          this.isLoaded = true;
        });
      }
    },
    handleShowDetail () {
      this.circleInfoShow = true;
    },
    like (res, index) {
      console.log(res);
      this.topicData[index].isThumbsUp = !res.isThumbsUp;
      // 取消点赞
      if (res.isThumbsUp) {
        let data = this.topicData[index];
        let count = data.articleStatisticVO.thumbsUpCount - 1;
        if (count < 0) count = 0;
        data.articleStatisticVO.thumbsUpCount = count;
        removeThumbsup({
          entityId: res.articleId,
          entityType: 1,
        });
      } else {
        // 添加点赞
        let data = this.topicData[index];
        data.articleStatisticVO.thumbsUpCount =
          data.articleStatisticVO.thumbsUpCount + 1;
        addThumbsup({
          entityId: res.articleId,
          entityType: 1,
        });
      }
    },
    favorite (res, index) {
      this.topicData[index].isFavorites = !res.isFavorites;
      // 取消收藏
      if (res.isFavorites) {
        let data = this.topicData[index];
        let count = data.articleStatisticVO.favoritesCount - 1;
        if (count < 0) count = 0;
        data.articleStatisticVO.favoritesCount = count;
        removeFavorites({
          entityId: res.articleId,
          entityType: "1",
        });
      } else {
        let data = this.topicData[index];
        // if (!data.articleStatisticVO) {
        //   data.articleStatisticVO = {}
        // }
        data.articleStatisticVO.favoritesCount =
          data.articleStatisticVO.favoritesCount + 1;
        // 添加收藏
        addFavorites({
          entityId: res.articleId,
          entityType: "1",
        });
      }
    },

    handleNewActive (item) {
      const index = this.topicData.findIndex((i) => {
        return i.articleId === item.articleId;
      });
      console.log(this.topicData[index]);
      this.topicData[index].articleVoteVO = item;
    },

    handleTopicClick ({articleId} = {}) {
      this.$router.push({
        name: "topicDetail",
        query: {id: articleId, circleShow: true},
      });
    },
    handleTagClick (tag) {
      this.$router.push({name: "searchTag", query: {tag: tag}});
    },

    handleDeleteAtt (index) {
      this.attList.splice(index, 1);
    },

    /**
     * 分享事件
     */
    handleShare ({id: quoteId} = {}) {
      this.$router.push({name: "createTopic", query: {quoteId}});
    },

    /**
     * 添加评论
     * @param item
     */
    handleComment (item) {
      this.showCommentDialog = true;
      this.commentParams = item;
    },

    /**
     * 发布评论
     */
    async handlePublishComment () {
      const {
        commentContent: content,
        commentParams: {articleId: entityId},
        isAnonymousComment: isAnonymous,
        attList,
      } = this;
      const entityType = ENTITY_TYPE_ARTICLE;
      if (!content.trim()) {
        this.$notify({message: "评论内容不能为空", type: "danger"});
        return;
      }
      const atUserIds = attList.map((item) => {
        return item.attId;
      });
      await addComment({
        content,
        entityId,
        entityType,
        isAnonymous,
        atUserIds,
      });
      this.attList = [];
      this.showCommentDialog = false;
      this.commentParams = {};
      this.commentContent = "";
      this.topicData = this.topicData.map((item) => {
        if (item.articleId !== entityId) {
          return item;
        }
        const {articleStatisticVO} = item;
        return {
          ...item,
          articleStatisticVO: {
            ...articleStatisticVO,
            commentCount: articleStatisticVO.commentCount + 1,
          },
        };
      });
    },

    /**
     * 跳转用户详情页
     */
    handleToUserDetail (userId) {
      this.$router.push({name: "userInfoDetail", params: {userId}});
    },

    /**
     * 申请/加入 右侧按钮
     */
    handleJoinBtn () {
      this.setApplyJoin(this.circleDetail);
    },
    /**
     * 去空格
     */
    handleConfirmApply () {
      this.applyReason = this.applyReason.trim();
      if (this.applyReason) {
        this.setApplyJoin(this.applyQuitItem);
      }
    },
    /**
     * 退出圈子
     */
    handleQuitCircle () {
      this.getCircleDetail()
    },
    /**
     * 需要申请加入
     */
    handleJoinApplyBtn () {
      this.applyQuitItem = this.circleDetail;
      this.isShowApply = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.applyReason.focus()
        }, 500)
      })
    },

    /**
     * todo 申请加入圈子接口
     * @returns {Promise<void>}
     */
    async setApplyJoin (item) {
      const data = {
        applyReason: this.applyReason,
        circleId: item.id,
      };
      await setApplyJoin(data);
      if (item.isJoinApply) {
        this.$toast({
          message: "加入申请提交成功",
          position: 'top'
        });
      } else {
        this.$toast({
          message: "加入成功",
          position: 'top'
        });
      }
      await this.getCircleDetail();
    },
    /**
     * todo 根据id获取圈子详情
     * @returns {Promise<void>}
     */
    async getCircleDetail () {
      if (!this.$route.query.id) {
        return
      }
      try {
        const res = (await getCircleDetail(this.$route.query.id)) || {};
        // const {data} = res
        this.circleDetail = res;
        this.isPublicPrivate = !this.circleDetail.isPublic;
        this.isApplySwitch = this.circleDetail.isJoinApply;
        console.log(res);
      } catch (e) {
        // this.$router.go(-1)
        this.circleDetail = {}
      }
    },
    // 点击按钮
    joinBtn (item) {
      if (item.type === 0) {
        this.show = true;
      } else if (item.type === 1) {
        this.$notify("加入成功!");
      } else if (item.type === 2) {
        this.showDiaolog = true;
      } else if (item.type === 3) {
        this.showDiaolog = true;
      }
    },
    onCancel () {
      this.show = false;
    },
    select (v) {
      if (v.name === "退出") {
        this.$dialog
          .confirm({
            title: "提示",
            message: "你确定要退出吗？",
          })
          .then(() => {
            this.show = false;
          });
      }
    },
    // 右侧点击
    rightIcon () {
      this.rigthShow = true;
    },
    rightSelect (v) {
      // if (v.name === '置顶') {

      // }
      if (v.name === "删除") {
        this.$dialog
          .confirm({
            title: "提示",
            message: "你确定要删除该帖子吗？删除后将消息通知发帖人",
          })
          .then(() => {
            this.rigthShow = false;
          });
      }
    },
    // 右侧取消
    rightOnCancel () {
      this.rigthShow = false;
    },
    // 右侧点击
    rightIconClick (i) {
      if (i === 1) {
        // 跳转圈子管理
        this.$router.push({name: "circleQgl"});
      } else {
        this.circelInfoShow = true;
      }
    },
    // 搜索圈子
    searchCircle () {
      this.$router.push({
        name: "circleSearch",
        query: {circleid: this.circleDetail.id},
      });
    },
    // 跳转
    handlerAdd () {
      if (this.circleDetail.identity == 100) {
        return this.$notify({type: "warning", message: "请加入圈子后再发帖"});
      }
      if (this.circleDetail.isMuted) {
        return this.$notify({type: "warning", message: "您已被圈子禁言"});
      }
      const obj = {};
      obj.id = this.circleDetail.id;
      obj.title = this.circleDetail.title;
      obj.isPublic = this.circleDetail.isPublic
      let src = JSON.stringify(obj);
      this.$router.push({
        name: "createTopic",
        query: {circleDetail: src, isPublic: this.circleDetail.isPublic},
      });
    },
    // 帖子列表点击回调数据
    currCard (item) {
      console.log(item);
      this.$router.push({name: "circleTz", query: {id: item.id}});
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .van_popup_ {
    .van-popup {
      border-radius: 12px 12px 0 0;
    }
  }
}

.colf6f6 {
  background: #f6f6f6;
  color: #6d7278;
}

.colfe5 {
  background: #fe5800;
  color: #fff;
}

.col6D7278 {
  color: #6d7278;
}

.circleXq_main {
  display: flex;
  height: 100%;
  flex-direction: column;

  .message_scroll {
    overflow-y: scroll;
    height: 100vh;
    flex: 1;
    background-color: #ffffff;
  }

  .banner_back {
    height: 162px;
  }

  .circle_content {
    height: auto;
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    position: relative;
    padding: 18px 15px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: -20px;

    .left {
      font-size: 15px;
      color: #000000;
      float: left;
    }

    .right {
      float: right;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .img1 {
        padding-left: 20px;
        width: 16px;
        height: 16px;
      }

      .img2 {
        width: 10px;
        height: 10px;
        position: relative;
        top: -3px;
      }

      span {
        font-size: 12px;
        color: #6d7278;
        display: inline-block;
        margin: 0 6px 0 16px;
        position: relative;
        top: -3px;
      }
    }
  }

  .addBtn {
    position: fixed;
    width: 80px;
    bottom: 80px;
    right: 20px;
    z-index: 1001;

    img {
      width: 55px;
      z-index: 20;
      position: relative;
      left: 12px;
      top: 3px;
    }

    .wave {
      position: absolute;
      top: calc((100% - 30px) / 2);
      left: calc((100% - 30px) / 2);
      width: 30px;
      height: 30px;
      border-radius: 300px;
    }

    .wave0 {
      z-index: 2;
      background-size: auto 106%;
      animation: w 2s forwards infinite;
    }

    @keyframes w {
      0% {
        background: rgba(255, 69, 51, 0.5);
        top: calc((100% - 30px) / 2);
        left: calc((100% - 30px) / 2);
        width: 30px;
        height: 30px;
      }
      100% {
        background: transparent;
        top: calc((100% - 120px) / 2);
        left: calc((100% - 120px) / 2);
        width: 120px;
        height: 120px;
      }
    }
  }
}
</style>
