<template>
  <div>
    <van-action-sheet
      cancel-text="取消"
      v-model="isShowAction"
      :actions="menuOption"
      @select="handleSelect" />
    <div class="topicView">
      <div class="topicView_head">
        <userInfoView
          :userBasicVO="data.userBasicVO"
          :icon-url="isAnonymous ? '' : data.userBasicVO.avatar || avatar || ''"
          :user-name="isAnonymous ? '匿名' : data.userBasicVO.username"
          :time="data.createTime"
          :admin="data.superAdmin"
          :deptStr="deptStr"
          :ellipsis="attFormat.attCount > 0"
          @click.native.stop="handleUserinfoClick"
        />
        <!-- todo 圈主和管理员有权限，只在圈子详情页展示-->
        <div v-if="isShowAngleMenu()" class="dropdown-menu">
          <div class="flex-center-row img-box" @click="handleOpenMenuAction">
            <img src="/images/organization/icon-arrow.png" alt="">
          </div>
        </div>
        <!--      <follow v-if='data.status === 0 && data.isVote !== 1 && data.checkStatus === 1' :count='data.commonFollowCount' :myFollow='data.myFollow'-->
        <!--              :class="{'headFlex': attFormat.attCount > 0}" :show-line='attFormat.attCount > 0' @follow='handleFollow'/>-->
        <!--      <userInfoView v-if='attFormat.attCount > 0' v-bind='attFormat' att-info class='headFlex' ellipsis/>-->
        <slot name="headLeft">
          <p
            class="checkStatus"
            v-if="showCheckStatus"
            :class="data.checkStatus ? 'active' : ''"
          >
            {{ checkStatus }}
          </p>
        </slot>
      </div>
      <!-- todo 帖子内容-->
      <div @click="handleClick" class="topicView_content">
        <topicContent
          :is-top="data.isTop"
          :title="data.title"
          :content="data.content"
          :topicList="data.topicTitles"
          :isAddVote="data.isAddVote"
          :articleVoteVO="data.articleVoteVO"
          :articleLabels="data.articleLabels"
          :attList="data.attList"
          :isDetail="isDetail"
          :img-list="data.imageList"
          :main-img-url="mainImgUrl"
          :videoAddr="data.videoUrl"
          :showImgList="data.imageList.length > 1"
          :showVote="data.isVote === 1"
          :hideTitle="data.isVote === 1"
          :voteList="data.voteList"
          :pattern="data.pattern"
          :voteCount="data.voteCount"
          :isMeVoted="data.voteFlag"
          :voteStartTime="data.voteStartTime"
          :voteEndTime="data.voteEndTime"
          :isList="isList"
          :locationTips="data.locationTips"
          :circleShows="circleShow"
          @click="handleCheckVote"
          @vote="handlePublicVote"
          @tagClick="handleTagClick"
          @attClick="handleAttClick"
          :articleId="data.articleId"
          @handleNewActive="handleNewActive"
        />
        <topicOverview
          v-if="isForward"
          v-bind="originalDataFormat"
          @click.stop.native="handleOriginalOverviewClick"
          @voteClick="handleOriginalOverviewClick"
        />
      </div>
      <!--    <appraise :data="data" />-->
      <!--  todo    底部的评论，点赞，收藏，查看人数等工具栏-->
      <toolbar
        v-if="showOptions"
        @click="handleToolbarClick"
        :value="toolbarData"
        :browseCount="browseCount"
      />
    </div>
    <div class="cut-line"></div>
  </div>
</template>

<script>
import userInfoView from "./components/userInfo";
import toolbar from "./components/toolbar";
import topicContent from "./components/content";
// import follow from "./components/follow";
// import appraise from "./components/appraise";
import topicOverview from "./components/overview";
import * as quoteType from "@/constant/quoteType";
import {ADMIN_NAME} from "@/config/config";
import {circleArticleCancelTop, circleArticleRemove, circleArticleTop} from "@/api/article";
import {addFavorites, removeFavorites} from "@/api/favorites";
import {mapGetters} from "vuex";

export default {
  props: {
    // 圈子详情
    circleDetail: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    showToolbar: {
      type: Boolean,
      default: () => true,
    },
    isDetail: Boolean,
    showOptions: {
      type: Boolean,
      default: true,
    },
    avatar: String,
    hideComment: Boolean,
    isList: Boolean,
    showCheckStatus: Boolean,
    circleShow: Boolean,
  },
  components: {
    userInfoView,
    toolbar,
    topicContent,
    topicOverview,
    // follow,
    // appraise
  },
  data() {
    return {
      toolBarTimer: null,
      menuValue: false,
      isShowAction: false,
      menuOption: [
        {
          name: '置顶帖子',
          type: 'top'
        },
        {
          name: '收藏帖子',
          type: 'collect'
        },
        {
          name: '删除帖子',
          type: 'delete'
        }
      ],
    };
  },
  mounted() {
    console.log(this.circleShow);
  },
  computed: {
    ...mapGetters(['userDetail']),
    isAnonymous() {
      const {
        data: {isAnonymous},
      } = this;
      return isAnonymous;
    },
    isForward() {
      return quoteType.FORWARD === this.data.quoteType;
    },
    deptStr() {
      const {superAdmin, deptStr, anonymous} = this.data;
      if (deptStr) {
        return (superAdmin !== 1 && superAdmin !== 2) || anonymous
          ? deptStr.split("/")[0]
          : "";
      } else {
        return "";
      }
    },
    // imgList() {
    //   const {imageList} = this.data
    //   if (quoteType.FORWARD === this.data.quoteType) {
    //     return (imageList || "").split(',').slice(0, 3)
    //   } else {
    //     return (imageList || "").split(',')
    //   }
    // },
    mainImgUrl() {
      // const [mainImg] = this.imgList
      const [mainImg] = this.data.imageList;
      return mainImg;
    },
    originalDataFormat() {
      let {
        quoteArticleVO: {
          content,
          title,
          id,
          imageList,
          userName,
          anonymous,
          superAdmin,
          isVote,
          voteCount,
          voteFlag,
          voteStartTime,
          voteEndTime,
          voteList,
        } = {},
      } = this.data;
      const [imgUrl] = (imageList || "").split(",");
      if (anonymous) {
        userName = "匿名";
      } else if (superAdmin === 1 || superAdmin === 2) {
        userName = ADMIN_NAME;
      }
      return {
        content,
        title,
        id,
        imgUrl,
        userName,
        anonymous,
        superAdmin,
        isVote,
        voteCount,
        voteFlag,
        voteStartTime,
        voteEndTime,
        voteList,
      };
    },
    toolbarData() {
      const {commentCount, favoritesCount, browseCount, thumbsUpCount} =
        this.data.articleStatisticVO;
      const {isThumbsUp, isFavorites} = this.data;
      return {
        comment: {
          num: commentCount,
          isActive: false,
        },
        favorite: {
          num: favoritesCount,
          isActive: isFavorites,
        },
        like: {
          num: thumbsUpCount,
          isActive: isThumbsUp,
        },
        share: {
          num: browseCount,
          isActive: false,
        },
      };
    },
    checkStatus() {
      const status = this.data.checkStatus;
      if (status === 0) {
        return "待审核";
      }
      return "审核驳回";
    },
    browseCount() {
      return this.data.articleStatisticVO.browseCount;
    },
    attFormat() {
      const {attList} = this.data;
      return attList && attList.length
        ? {
          iconUrl: attList[0].attAvatar,
          attCount: attList.length,
          userName: attList[0].attName,
        }
        : {};
    },
  },
  methods: {
    // 是否显示下拉箭头
    isShowAngleMenu() {
      let flag = false
      if (this.$route.path !== "/app/circle/circleXq") {
        return false
      }
      if (this.circleDetail && this.circleDetail.identity < 3) {
        flag = true
      } else {
        flag = false
        return flag
      }

      let manageList = this.circleDetail.leadAndManageMembers || []
      manageList = manageList.filter(item => item.userBasicVO.userId === this.userDetail.userId)
      if (manageList.length === 0) {
        return false
      }
      return flag
    },
    // 打开菜单弹框
    handleOpenMenuAction() {
      if (this.data.isTop) {
        this.menuOption.filter(item => item.type === 'top')[0].name = '取消置顶'
      } else {
        this.menuOption.filter(item => item.type === 'top')[0].name = '置顶帖子'
      }
      if (this.data.isFavorites) {
        this.menuOption.filter(item => item.type === 'collect')[0].name = '取消收藏'
      } else {
        this.menuOption.filter(item => item.type === 'collect')[0].name = '收藏帖子'
      }
      this.isShowAction = true
    },
    // 选择菜单回调
    handleSelect(item) {
      const articleId = this.data.articleId
      if (item.type === 'delete') {
        this.$dialog.confirm({
          title: '删除帖子',
          message: '你确定要删除该帖子吗？',
          confirmButtonText: '删除帖子',
          cancelButtonText: '再考虑下'
        }).then(() => {
          circleArticleRemove({articleId: articleId}).then(() => {
            this.$emit('deleteArticle', articleId)
          });
        })
      }
      if (item.type === 'top') {
        console.log('top')
        if (this.data.isTop) {
          try {
            circleArticleCancelTop({articleId: articleId}).then(() => {
              this.$emit('deleteArticle', articleId)
              this.$toast({
                message: '取消置顶成功',
                position: 'top'
              })
              this.data.isTop = false
            });
          } catch (e) {
            console.log(e.response)
          }
        } else {
          try {
            circleArticleTop({articleId: articleId}).then(() => {
              this.$emit('deleteArticle', articleId)
              this.data.isTop = true
              this.$toast({
                message: '置顶成功',
                position: 'top'
              })
            });
          } catch (e) {
            console.log(e.response)
          }
        }
      }
      if (item.type === 'collect') {
        if (this.data.isFavorites) {
          removeFavorites({
            entityId: articleId,
            entityType: '1'
          }).then(() => {
            this.data.isFavorites = false
            this.data.articleStatisticVO.favoritesCount = this.data.articleStatisticVO.favoritesCount - 1
          })
        } else {
          // 添加收藏
          addFavorites({
            entityId: articleId,
            entityType: '1'
          }).then(() => {
            this.data.isFavorites = true
            this.data.articleStatisticVO.favoritesCount = this.data.articleStatisticVO.favoritesCount + 1
          })
        }
      }
      this.isShowAction = false
    },
    handleChangeMenu(value) {
      console.log('value')
      console.log(value)
    },
    handleNewActive(res) {
      this.$emit("handleNewActive", res);
    },
    handleToolbarClick(val) {
      clearTimeout(this.toolBarTimer);
      this.toolBarTimer = setTimeout(() => {
        const {key} = val;
        this.$emit(key, {...val, ...this.data});
      }, 100);
    },
    getUrls(urls) {
      if (urls) {
        let split = urls.split(",");
        return split[0];
      }
      return urls;
    },
    handleClick() {
      this.$emit("click", this.data);
    },
    handleOriginalOverviewClick() {
      this.$emit("originalClick", this.originalDataFormat);
    },
    handleUserinfoClick() {
      if (this.isAnonymous) return;
      this.$emit("userInfoClick");
    },
    handleCheckVote(item) {
      this.$emit("checkVote", item);
    },
    handlePublicVote() {
      this.$emit("vote");
    },
    handleTagClick(tag) {
      this.$emit("tagClick", tag);
    },
    handleAttClick(id) {
      this.$emit("attClick", id);
    },
    handleFollow() {
      this.$emit("follow");
    },
  },
};
</script>

<style lang="scss" scoped>
.topicView {
  padding: 20px;
  background-color: white;
  margin: 0;
  //border-radius: 6px;

  .dropdown-menu {
    position: relative;

    .img-box {
      width: 20px;
      height: 20px;

      > img {
        width: 16px;
        height: 16px;
      }
    }

    ul {
      width: 80px;
      background-color: #FFFFFF;
      position: absolute;
      top: 20px;
      right: 0;
      text-align: right;
    }
  }

  .checkStatus {
    font-size: 12px;
  }

  &_head {
    display: flex;
    justify-content: space-between;

    .checkStatus {
      margin: 0;
      margin-top: 4px;
      color: $remarkColor;
      text-align: center;
    }

    .active {
      color: $mainColor;
    }
  }

  &_content {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.cut-line {
  height: 1px;
  padding: 0 12px;
  margin: 0 22px;
  background-color: #f4f4f4;
}
</style>
