import request from "@/utils/request";

/**
 * 分页查询评论列表
 * @param entityId
 * @param entityType
 * @param pageNum
 * @param pageSize
 * @returns {AxiosPromise}
 */
export function getCommentList(data) {
    data.pageSize = 10
    data.entityType = 2
    return request({
        url: '/api/zeekr-article-new/comment/listFirstLevelComment',
        method: 'post',
        data
    })
}
export function getCommentListSecond(params) {
    return request({
        url: '/api/zeekr-article-new/comment/listSecondLevelComment',
        method: 'get',
        params
    })
}
/**
 * 添加评论
 * @param content
 * @param entityId
 * @param entityType
 * @param anonymous
 * @returns {AxiosPromise}
 */
export function addComment(data) {
    return request({
        url: '/api/zeekr-article-new/comment/publish',
        method: "post",
        data
    })
}
/**
 * 删除评论
 * @param id
 * @returns {AxiosPromise}
 */
export function removeComment(id) {
    return request({
        url: '/api/zeekr-article-new/comment/delete',
        method: "get",
        params: {
            commentId: id
        }
    })
}

