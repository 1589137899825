import request from "@/utils/request";

// 闪布login
export function addThumbsup(data) {
    return request({
        url: '/api/zeekr-article-new/thumbsUp/save',
        method: 'post',
        data
    })
}


// 闪布login
export function removeThumbsup(data) {
    return request({
        url: '/api/zeekr-article-new/thumbsUp/cancel',
        method: 'post',
        data
    })
}

// 我的点赞
export function myThumbsup(params) {
    return request({
        url: '/api/zeekr-article-new/thumbsUp/listFromMine',
        method: 'get',
        params
    })
}

/**
 * 获取点赞列表
 * @param entityId
 * @param entityType
 * @param pageNum
 * @param pageSize
 */
export function getThumbsupList(params) {
    return request({
        url: '/api/zeekr-article-new/thumbsUp/listToArticle',
        method: 'get',
        params
    })
}
