<template>
  <div class="topicCommunicateView">
    <div class="line"></div> <div class="text">{{text}}</div> <div class="line"></div>
  </div>
</template>

<script>
export default {
  name: "NoMore",
  props: {
    text: {
      type: String,
      default: "我是有底线的"
    },
  },
}
</script>

<style lang="scss" scoped>
.topicCommunicateView{
  display: flex;
  width: 100%;
  height: 30px;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  .line{
    width: 75px;
    height: 1px;
    background: #CCCCCC;
    display: inline-block;
    margin: 10px 0;
  }
  .text{
    font-size: 13px;
    color: #999999;
    display: inline-block;
    margin: 0 10px
  }
}
</style>
