/**
 * 检查手机高度
 */
import store from "@/store";


export function checkHeight() {
    let origin = window.innerHeight;
    setTimeout(() => {
        let androidVal = origin - window.innerHeight === 0;
        // 只有不是安卓时添加，默认是苹果
        if(!androidVal){
            store.commit("user/SET_DEVICE",androidVal)
        }
    }, 500)

}


export function isAndroid(){
    var u = navigator.userAgent;
    return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
}
