import { render, staticRenderFns } from "./NoMore.vue?vue&type=template&id=457d62c8&scoped=true&"
import script from "./NoMore.vue?vue&type=script&lang=js&"
export * from "./NoMore.vue?vue&type=script&lang=js&"
import style0 from "./NoMore.vue?vue&type=style&index=0&id=457d62c8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "457d62c8",
  null
  
)

export default component.exports