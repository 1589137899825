<template>
  <div class="toolbar">
    <div v-for="item in options" :key="item.key" class="toolbarItem" @click.stop="handleClick(item)">
      <img :src="value[item.key]&&value[item.key].isActive?item.activeIconUrl:item.normalIconUrl"
           class="toolbarItem_icon" alt=""/>
      <p class="toolbarItem_value">{{ getDisplayVal(item.key) }}</p>
    </div>
    <div class="browse">
      <img src="/images/topicItem/view.png" alt="" />
      <span class="count">{{browseCount}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    browseCount: Number
  },
  data() {
    const iconBasePath = '/images/topicItemToolbar'
    return {
      options: [
        {
          key: 'comment',
          normalIconUrl: `${iconBasePath}/commentNormal.png`,
          activeIconUrl: `${iconBasePath}/commentActive.png`
        },
        {
          key: 'like',
          normalIconUrl: `${iconBasePath}/likeNormal.png`,
          activeIconUrl: `${iconBasePath}/likeActive.png`
        },
        {
          key: 'favorite',
          normalIconUrl: `${iconBasePath}/favoriteNormal.png`,
          activeIconUrl: `${iconBasePath}/favoriteActive.png`
        },
        /*{
          key: 'share',
          normalIconUrl: `${iconBasePath}/shareNormal.png`,
          activeIconUrl: `${iconBasePath}/shareActive.png`
        },*/
      ]
    }
  },
  methods: {
    handleClick(data) {
      this.$emit('click', data)
    },
    getDisplayVal(key) {
      const {num} = this.value[key] || {}
      return num > 0 ? num : key==='comment'?'抢首评':key==='like'?'点赞':'收藏'
    }
  }
}
</script>

<style lang="scss" scoped>
.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .browse {
    font-size: 10px;
    color: $mainColor;
    background-color: #F6F6F6;
    border-radius: 8px;
    padding: 1px 7px;
    display: flex;
    align-items: center;

    img {
      width: 10px;
      margin-right: 3px;
    }
  }
}

.toolbarItem {
  display: flex;
  align-items: center;


  &_icon {
    height: 16px;
    margin-right: 5px;
  }

  &_value {
    @include remark;
    white-space:nowrap;
  }
}
</style>
