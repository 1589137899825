<template>
  <div class="flex-row-space-between-hCenter header-info">
    <div class="flex-row-h-center" @click="handleShowDetail">
      <img class="header-img" :src="circleDetail.avatar" />
      <div class="centerInfo">
        <div class="flex-row-h-center">
          <h3 class="title" v-if="circleDetail.title">{{ circleDetail.title | substrCircleTitle }}</h3>
          <van-tag style="margin-left: 8px" size="medium" v-if="!circleDetail.isPublic" color="#FE5800" plain
            type="primary">私密
          </van-tag>
        </div>
        <div class="flex-row-h-center centerInfo_of">
          <p class="center_header" v-if="circleDetail.circleTypeTitle">
            {{ circleDetail.circleTypeTitle.substr(0, 4) }} |
          </p>
          <!-- 人员-->
          <div class="flex-row-h-center circle-number" @click.stop="showAllMember">
            <img src="../../../../../assets/imgs/circle/Slice51@2x.png" />
            <span v-if="circleDetail.circleStatisticVO">{{
                circleDetail.circleStatisticVO.memberCount
              }}</span>
          </div>
          <div class="flex-row-h-center circle-number">
            <img src="../../../../../assets/imgs/circle/Slice50@2x.png" />
            <span v-if="circleDetail.circleStatisticVO">{{
                circleDetail.circleStatisticVO.articleCount
              }}</span>
          </div>
        </div>
        <p class="circle-intro" v-html="getBrIntroduction(circleDetail.introduction)">{{ circleDetail.introduction }}</p>
      </div>
    </div>
    <!--todo    加入圈子，有的需要申请-->
    <!--    <div class="flex-center-row right-btn-add" v-if="circleDetail.identity === 100" @click.stop="joinCircleBtn(header)">-->
    <!--      <span> +</span>-->
    <!--      <span v-if="circleDetail.isJoinApply">申请</span>-->
    <!--      <span v-else>加入</span>-->
    <!--    </div>-->
    <van-button
      v-if="circleDetail.identity === 100"
      @click="joinCircleBtn()"
      color="#fe5800"
      round
      size="mini"
      icon="plus"
      type="primary"
    >
      <span v-if="circleDetail.isJoinApply">申请</span>
      <span v-else>加入</span>
    </van-button>
    <!-- todo 管理员操作-->
    <div v-else class="flex-row-h-center right-operate">
      <img
        class="setting-img"
        v-if="circleDetail.identity === 1 || circleDetail.identity === 2"
        src="../../../../../assets/imgs/circle/drawable-mdpi_Slice41.png"
        @click.prevent="goCircleManage()"
      />
      <img
        class="menu-img"
        src="../../../../../assets/imgs/circle/drawable-mdpi_Slice42.png"
        @click.prevent="openAction()"
      />
    </div>
    <div v-if="false" class="button_box">
      <van-action-sheet
        v-model="show"
        :actions="actions"
        cancel-text="取消"
        close-on-click-
        @select="select"
        @cancel="onCancel"
      />
      <van-dialog
        v-model="showDiaolog"
        title="进入圈子申请"
        show-cancel-button
        get-container="#app">
        <div class="" style="text-align: center">
          <van-field
            v-model="ly"
            placeholder="请输入申请理由！"
            :rules="[{ required: true, message: '请输入申请理由！' }]"
          />
        </div>
      </van-dialog>
    </div>
    <van-action-sheet
      v-model="isShowAction"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="handleSelect"
      @cancel="handleCancel"
    ></van-action-sheet>
  </div>
</template>

<script>
import {setDismissCircle, setQuitCircle} from "@/api/circle";

export default {
  name: "headerInfo",
  props: {
    circleDetail: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      isShowAction: false,
      actions: [], // 设置圈子提示类型
    };
  },
  methods: {
    // 替换换行符
    getBrIntroduction (text) {
      if (text) {
        return text.replace(/\n/g, '<br />')
      }else {
        return ''
      }
    },
    // 查看全部成员
    showAllMember() {
      console.log(this.circleDetail)
      this.$router.push({
        path: '/app/circle/circleManagePerson',
        query: {
          circleId: this.circleDetail.id
        }
      })
    },
    handleShowDetail() {
      this.$emit("showDetail");
    },
    /**
     * todo 解散圈子
     */
    async setDismissCircle() {
      const id = this.circleDetail.id;
      if (id) {
        await setDismissCircle(id);
        this.$toast({
          message: "解散成功",
          position: 'top'
        });
        this.$router.go(-1);
      }
    },
    /**
     * todo 退出圈子
     */
    async setQuitCircle() {
      const id = this.circleDetail.id;
      console.log(id);
      if (id) {
        await setQuitCircle(id);
        this.circleDetail.identity = 100;
        this.$emit('quitCircle', 'quit')
      }
    },
    // 打开Action
    openAction() {
      const identity = this.circleDetail.identity;
      if (identity === 1) {
        this.actions = [
          {name: "管理圈子"},
          {name: "解散圈子", color: "#F42626"},
        ];
      } else if (identity === 2) {
        this.actions = [
          {name: "管理圈子"},
          {name: "退出圈子", color: "#F42626"},
        ];
      } else {
        this.actions = [{name: "退出圈子", color: "#F42626"}];
      }
      this.isShowAction = true;
    },
    handleSelect(value) {
      // 退出圈子
      if (value.name === "退出圈子") {
        let message = "你确定要退出该圈子吗？";
        if (this.circleDetail.identity === 2) {
          message = "你确定要退出该圈子吗？退出后你将失去管理员权限";
        }else if(!this.circleDetail.isPublic){
          message = "你确定要退出该私密圈子吗？退出后再加入需要圈主邀请";
        }
        this.$dialog
          .confirm({
            title: "退出圈子",
            message: message,
            confirmButtonText: "退出圈子",
            cancelButtonText: "再考虑下",
          })
          .then(() => {
            this.setQuitCircle();
            // this.$router.go(-1);
          });
      }
      // 解散圈子
      if (value.name === "解散圈子") {
        this.$dialog
          .confirm({
            title: "解散圈子",
            message: "解散后无法还原，确定解散当前圈子吗？",
            confirmButtonText: "解散圈子",
            cancelButtonText: "再考虑下",
          })
          .then(() => {
            this.setDismissCircle();
          });
      }

      if (value.name === "管理圈子") {
        this.goCircleManage();
      }
    },
    handleCancel(value) {
      console.log(value);
    },
    // 加入圈子
    joinCircleBtn() {
      if (this.circleDetail.isJoinApply) {
        this.$emit("joinAppleBtn");
      } else {
        this.$emit("joinBtn");
      }
    },
    // 跳转至管理圈子
    goCircleManage() {
      this.$router.push({
        path: "/app/circle/circleJbglsz",
        query: {
          id: this.circleDetail.id,
          from: 'circleDetail'
        },
      });
    },
  },
  filters: {
    substrCircleTitle(value) {
      value = value.replace(/&amp;/g, '&')
      if (value.length > 5) {
        return value.substr(0, 5) + '...'
      } else {
        return value
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .van-button--mini {
    width: 64px;
    height: 28px;
  }
}

.header-info {
  margin-top: 34px;
  padding: 0 15px;

  .header-img {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    object-fit: cover;
  }

  .centerInfo {
    display: flex;
    flex-direction: column;
    margin: 0 12px;

    .title {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: bold;
      color: #ffffff;
      margin-right: 6px;
    }

    .centerInfo_of {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.9);
      letter-spacing: 0;
      margin-top: 4px;

      .center_header {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.9);
        letter-spacing: 0;
      }

      .circle-number {
        margin-left: 8px;

        > img {
          width: 12px;
          height: 12px;
        }

        > span {
          margin-left: 8px;
        }
      }
    }

    .circle-intro {
      width: 182px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.9);
      letter-spacing: 0;
      margin-top: 4px;
      word-break: break-all;

      //width: auto;
      /*下面设置文本内容为单行，超出隐藏并显示三个点*/
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /*设置显示的行数*/
      line-clamp: 2; /*设置显示的行数*/
      -webkit-box-orient: vertical;
    }
  }

  .right-btn-add {
    width: 49px;
    height: 24px;
    background: #fc6601;
    border-radius: 50px;

    > span {
      font-family: "PingFang SC";
      font-size: 14px;
      color: #ffffff;
    }
  }

  .right-operate {
    > img {
      width: 16px;
      height: 16px;
      padding: 0 8px;
    }

    .setting-img {
    }

    .menu-img {
    }
  }

  .button_box {
    display: inline-block;

    .right_box {
      display: flex;

      .right {
        width: 64px;
        height: 28px;
        border-radius: 14px;
        font-size: 13px;
        text-align: CENTER;
        line-height: 28px;
        position: relative;
        top: 20px;
      }

      .right2 {
        position: relative;
        right: -20px;

        span {
          display: inline-block;
          width: 16px;
        }

        .icon {
          width: 16px;
          height: 16px;
          display: inline-block;
          background: url("~@/assets/imgs/circle/Slice41@2x.png");
          background-size: 100%;
        }
      }
    }
  }
}
</style>
