<template>
  <div class="circleInfo">
    <div class="flex-row banner_back">
      <img class="header-avatar" :src="circleDetail.avatar" />
      <div class="centerInfo">
        <h3 class="h3">{{ circleDetail.title }}</h3>
        <div class="centerInfo_of">
          <p class="center_header">圈子诞生日：{{ circleDetail.createTime }}</p>
        </div>
      </div>
    </div>
    <div class="jj_cont">
      <div class="qjj_box">
        <div class="h3">圈简介</div>
        <div v-html="getBrIntroduction(circleDetail.introduction)" class="p">{{ circleDetail.introduction }}</div>
      </div>
      <div class="myJoin" v-if="false">
        <div class="myJoin_title">圈主/管理员</div>
        <div class="circleList"
          v-if="circleDetail.leadAndManageMembers && circleDetail.leadAndManageMembers.length > 0">
          <van-grid :border="false" :column-num="5">
            <van-grid-item @click="goUserHome(item.userBasicVO.userId)"
              v-for="item in circleDetail.leadAndManageMembers"
              :key="item.userBasicVO.userId">
              <van-image
                round
                fit="cover"
                position="left"
                width="46px"
                height="46px"
                :src="item.userBasicVO.avatar"
              />
              <div class="manage-name">{{ item.userBasicVO.username }}</div>
              <p class="circle-leader">
                <span v-if="item.identity === 1">(圈主)</span>
                <span v-if="item.identity === 2">(管理员)</span>
              </p>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
      <div class="myJoin">
        <div class="qzGly">
          <span class="myJoin_title">圈成员</span>
          <!--          {{circleDetail.circleStatisticVO.memberCount}}-->
          <span class="myJoin_title_ri" @click="van_all_per">查看全部{{circleDetail.circleStatisticVO.memberCount}}位成员 ></span>
        </div>
        <div class="circleList" v-if="circleDetail.normalMembers || circleDetail.leadAndManageMembers">
          <van-grid :border="false" :column-num="5">
            <van-grid-item @click="goUserHome(item.userBasicVO.userId)"
              v-for="item in circleDetail.leadAndManageMembers"
              :key="item.userBasicVO.userId">
              <van-image
                round
                fit="cover"
                position="left"
                width="46px"
                height="46px"
                :src="item.userBasicVO.avatar"
              />
              <div class="manage-name">
                <span v-if="item.userBasicVO && item.userBasicVO.username.length > 3">{{item.userBasicVO.username.substr(0,3)}}...</span>
                <span v-else>{{ item.userBasicVO.username }}</span>
                </div>
              <p class="circle-leader">
                <span v-if="item.identity === 1">(圈主)</span>
                <span v-if="item.identity === 2">(管理员)</span>
              </p>
            </van-grid-item>
            <van-grid-item
              @click="goUserHome(item.userBasicVO.userId)"
              v-for="item in circleDetail.normalMembers.slice(0,10-circleDetail.leadAndManageMembers.length)"
              :key="item.userBasicVO.userId">
              <van-image
                round
                fit="cover"
                position="left"
                width="46px"
                height="46px"
                :src="item.userBasicVO.avatar"
              />
              <div class="manage-name">{{ item.userBasicVO.username }}</div>
            </van-grid-item>
          </van-grid>
        </div>
        <div class="circle_noData" v-else>
          <span>暂无成员</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'circleInfo', // 圈子简介
  props: {
    circleDetail: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {
      list: []
    }
  },
  created() {
    this.list = this.circleDetail.normalMembers
    this.list = this.list.concat(this.list)
    this.list = this.list.concat(this.list)
    this.list = this.list.concat(this.list)
  },
  methods: {
    // 查看他人主页
    goUserHome(userId) {
      this.$router.push({
        path: `/app/home/userInfoDetail/${userId}`
      })
    },
    // 查看全部成员
    van_all_per() {
      // this.$router.push({name: "circleManagePeson"});
      this.$router.push({
        path: "/app/circle/circleManagePerson",
        query: {
          circleId: this.circleDetail.id
        }
      });
      localStorage.setItem('type', 0) // 设置下标
    },
    // 替换换行符
    getBrIntroduction (text) {
      if (text) {
        return text.replace(/\n/g, '<br />')
      }else {
        return ''
      }
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .van-grid-item__content {
    padding: 0 0 15px;
    justify-content: flex-start;
  }
}

.circleInfo {
  ::v-deep .van-badge--fixed {
    top: 5px;
  }

  .jj_cont {
    margin: 0 15px;
    //height: 380px;
    overflow: scroll;

    .myJoin {
      margin-top: 20px;

      .circle_noData{
        span{
          font-size: 14px;
          color: #333333;
        }
      }

      .myJoin_title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 15px;
        color: #000000;
      }

      .myJoin_title_ri {
        font-size: 12px;
        color: #6d7278;
        line-height: 20px;
      }

      .qzGly {
        display: flex;
        justify-content: space-between;
      }

      .circleList {
        color: #000000;

        .manage-name {
          font-size: 14px;
        }

        .circle-leader {
          font-size: 12px;
        }

        img {
          border-radius: 50%;
        }

        .right_kz {
          margin: 0 5px;

          span {
            background: #F6F6F6;
            border-radius: 3px;
            padding: 4px 6px;
            display: inline-block;
            font-size: 12px;
            position: relative;
            top: 25px;
          }
        }

        .item_cir {
          width: 71px;

          .cir_title {
            font-size: 12px;
            color: #333333;
            text-indent: 6px;
            margin-top: 6px;
            letter-spacing: 0;
          }
        }
      }
    }
  }

  .banner_back {
    padding: 18px 0 16px;
    display: flex;
    color: #fff;
    margin: 0 15px;
    border-bottom: 1px solid #f4f4f4;

    .header-avatar {
      width: 58px;
      height: 58px;
      border-radius: 50%;
      object-fit: cover;
    }

    .centerInfo {
      width: 182px;
      display: inline-block;
      margin: 0 12px;
      padding-top: 10px;

      .h3 {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 500;
        color: #171717;
        line-height: 16px;
      }

      .centerInfo_of {
        display: flex;
        font-size: 12px;
        letter-spacing: 0;
        width: 182px;
        margin-top: 4px;

        .center_header {
          display: flex;
          color: #6D7278;
        }

        .header_p1 {
          img {
            width: 12px;
            height: 12px;
          }

          span {
            display: inline-block;
            margin-left: 8px;
          }
        }
      }

      .header_p2 {
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0;
        margin-top: 4px;

        width: auto;
        /*下面设置文本内容为单行，超出隐藏并显示三个点*/
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /*设置显示的行数*/
        line-clamp: 2; /*设置显示的行数*/
        -webkit-box-orient: vertical;
      }
    }

    .button_box {
      display: inline-block;

      .right_box {
        display: flex;

        .right {
          width: 64px;
          height: 28px;
          border-radius: 14px;
          font-size: 13px;
          text-align: CENTER;
          line-height: 28px;
          position: relative;
          top: 20px;
        }

        .right2 {
          position: relative;
          right: -20px;

          span {
            display: inline-block;
            width: 16px;
          }

          .icon {
            width: 16px;
            height: 16px;
            display: inline-block;
            background: url('~@/assets/imgs/circle/Slice41@2x.png');
            background-size: 100%;
          }
        }
      }
    }
  }

  .qjj_box {
    margin-top: 20px;

    .h3 {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 15px;
      color: #000000;
    }

    .p {
      width: 326px;
      font-size: 14px;
      color: #333333;
      line-height: 22px;
    }
  }

}
</style>
