import request from "@/utils/request";

// 闪布login
export function addFavorites(data) {
    return request({
        url: '/api/zeekr-article-new/favorites/save',
        method: 'post',
        data
    })
}


// 闪布login
export function removeFavorites(data) {
    return request({
        url: '/api/zeekr-article-new/favorites/cancel',
        method: 'post',
        data
    })
}

/**
 * 我的收藏
 * @param pageNum
 * @param pageSize
 * @returns {AxiosPromise}
 */
export function myFavorites(params) {
    return request({
        url: '/api/zeekr-article-new/favorites/listFromMine',
        method: 'get',
        params
    })
}

/**
 * 我的发帖
 * @param pageNum
 * @param pageSize
 * @returns {AxiosPromise}
 */
export function myPosting(params) {
    return request({
        url: '/api/zeekr-article-new/article/listUser',
        method: 'get',
        params
    })
}